<template>
  <div class="policy-standard-detail-wrapper">
    <van-nav-bar
      fixed
      title="标准规范"
      left-arrow
      :border="false"
      @click-left="onClickLeft"
    >
    </van-nav-bar>
    <div class="policy-standard-detail-block">
      <div class="policy-standard-info-title">基本信息</div>
      <label-value-field label="标题" :value="info.name" />
      <label-value-field label="适用部门" :value="info.depts" />
      <label-value-field label="标准号" :value="info.no" />
      <label-value-field label="更新人" :value="info.uuserName" />
      <label-value-field label="制修订" :value="info.isReviseName" />
      <label-value-field label="更新时间" :value="info.utime" />
      <label-value-field label="标准类别" :value="info.type" />
      <label-value-field label="强制程度" :value="info.coerceDegreeName" />
      <label-value-field label="批准发布部门" :value="info.office" />
      <label-value-field label="技术归口" :value="info.techDept" />
      <label-value-field label="中国标准分类号" :value="info.chinaClassifyNo" />
      <label-value-field
        label="国际标准分类号"
        :value="info.internationClassifyNo"
      />
      <label-value-field label="发布日期" :value="info.publishDate" />
      <label-value-field label="实施日期" :value="info.executeDate" />
      <label-value-field label="废止日期" :value="info.abolishDate" />
      <label-value-field label="辨识日期" :value="info.identifyDate" />
      <label-value-field label="复查日期" :value="info.reviewDate" />
      <label-value-field label="起草单位" :value="info.draftOrg" />
      <label-value-field label="起草人" :value="info.draftPerson" />
      <label-value-field label="备案号" :value="info.recordNo" />
      <label-value-field label="备案日期" :value="info.recordDate" />
      <label-value-field label="备案公告" :value="info.noticeName" />
      <label-value-field label="备注" :value="info.remark" />
      <label-value-field label="关键词" :value="info.keywords" />
      <label-value-field
        label="附件信息"
        type="file"
        :value="info.attachmentList"
      />
    </div>
    <div class="policy-standard-detail-block">
      <div class="policy-standard-info-title">正文</div>
      <p class="policy-standard-info-content" v-html="info.content"></p>
    </div>
  </div>
</template>

<script>
import { getPolicyStandardInfo } from "@/api/psm";
import { coerceDegreeMap } from "@/views/secureRepository/policystandard/contants";

export default {
  props: {
    id: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      info: {},
      coerceDegreeMap,
      isReviseData: [
        {
          label: "制定",
          value: "0"
        },
        {
          label: "修订",
          value: "1"
        }
      ]
    };
  },
  created() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      if (!this.id) {
        this.onClickLeft();
        return;
      }
      // 获取详情
      getPolicyStandardInfo(this.id).then(res => {
        res.keywords = res.tagsVOList ? res.tagsVOList.join(",") : "";
        res.depts = Object.values(res.applyDepartments).join(",");
        res.isReviseName =
          this.isReviseData.find(o => o.value === res.isRevise)?.label || "--";
        res.coerceDegreeName = this.coerceDegreeMap[res.coerceDegree] || "--";
        this.info = res;
      });
    },
    onClickLeft() {
      this.$router.back();
    }
  }
};
</script>

<style lang="scss">
.policy-standard-detail-wrapper {
  padding-top: 60px;
  box-sizing: border-box;
  background-color: #eceef2;
  .policy-standard-info-title {
    font-size: 16px;
    color: #2e2e4d;
    font-weight: 700;
    margin-bottom: 16px;
  }
  .policy-standard-info-content {
    font-size: 14px;
    line-height: 20px;
  }
  .policy-standard-detail-block {
    margin-bottom: 8px;
    padding: 16px;
    background-color: #fff;
  }
}
</style>
